@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.App {
  min-height: 100vh;
  display: flex;
  background: #282d3e;
  /* background-image: url("./background.png"); */
  background-position: center;
  background-size: cover;
  font-family: "Roboto", sans-serif;
  font-weight: 200;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.281);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
